BODY {
  padding-bottom: 150px;
  position: relative;
}
#content {
  min-height: 300px;
}
.navbar-brand {
  width: 150px;
  height: inherit;
  padding: 5px;
}
.red {
  color: red !important;
}
.bold {
  font-weight: bold;
}
.italic {
  font-style: italic;
}
.margin-top-0 {
  margin-top: 0px;
}
.margin-bottom-0 {
  margin-bottom: 0px;
}
.margin-top-0-bottom-0 {
  margin-top: 0px;
  margin-bottom: 0px;
}
[contenteditable]:focus {
  outline: 2px dashed red;
  width: 99% !important;
  margin: 0 auto;
}
.mobil-h3 {
  border-bottom: 2px solid red;
}
.pdf {
  /*jelentkezo start*/
  /*jelentkezo end*/
  /*remenyi start*/
  /*remenyi end*/
  /*lehel start*/
  /*lehel end*/
}
.pdf .color-fekete {
  color: #000 !important;
}
.pdf .color-feher {
  color: #fff !important;
}
.pdf .color-kek {
  color: #296197 !important;
}
.pdf .color-piros {
  color: red !important;
}
.pdf .color-zold {
  color: green !important;
}
.pdf .color-szurke {
  color: #88898c !important;
}
.pdf .color-bordo {
  color: #6e1010 !important;
}
.pdf .color-bordo2 {
  color: #94070a !important;
}
.pdf .color-bezs {
  color: #e6cec0 !important;
}
.pdf .color-fekete {
  color: #333 !important;
}
.pdf .color-jelentkezo-zold {
  color: #63995a !important;
}
.pdf .color-remenyi-kek {
  color: #2190b2 !important;
}
.pdf .color-remenyi-piros {
  color: #d01d3a !important;
}
.pdf .color-remenyi-barna {
  color: #876d51 !important;
}
.pdf .bg-feher {
  background: #fff !important;
}
.pdf .bg-fekete {
  background: #000 !important;
}
.pdf .bg-kek,
.pdf .bg-kek-bak {
  background: #296197 !important;
}
.pdf .bg-bordo {
  background: #6e1010 !important;
}
.pdf .bg-bordo2 {
  background: #94070a !important;
}
.pdf .bg-piros {
  background: red !important;
}
.pdf .bg-zold {
  background: green !important;
}
.pdf .bg-szurke {
  background: #88898c !important;
}
.pdf .bg-vszurke {
  background: #ececec !important;
}
.pdf .bg-sszurke {
  background: #231f20 !important;
}
.pdf .bg-barna {
  background: #c3bea0 !important;
}
.pdf .bg-bezs {
  background: #e6cec0 !important;
}
.pdf .bg-jelentkezo-zold {
  background: #63995a !important;
}
.pdf .jelentkezo-vzold {
  background: #cfdcd0 !important;
}
.pdf .jelentkezo-vkek {
  background: #cccadb !important;
}
.pdf .jelentkezo-vbarna {
  background: #ddd2cb !important;
}
.pdf .bg-remenyi-kek {
  background: #2190b2 !important;
}
.pdf .remenyi-vkek {
  background: #83c2d5 !important;
}
.pdf .bg-remenyi-piros {
  background: #d01d3a !important;
}
.pdf .remenyi-vpiros {
  background: #e7838e !important;
}
.pdf .bg-remenyi-barna {
  background: #876d51 !important;
}
.pdf .remenyi-vbarna {
  background: #b69e83 !important;
}
.pdf .color-lehengerlo-zold {
  color: #20bda9 !important;
}
.pdf .color-lehengerlo-kek {
  color: #2098bd !important;
}
.pdf .color-lehengerlo-piros {
  color: #bd4520 !important;
}
.pdf .bg-lehengerlo-zold {
  background: #20bda9 !important;
}
.pdf .bg-lehengerlo-kek {
  background: #2098bd !important;
}
.pdf .bg-lehengerlo-piros {
  background: #bd4520 !important;
}
.pdf .line-height-10 * {
  line-height: 1em !important;
}
.pdf .line-height-15 * {
  line-height: 1.5em !important;
}
.pdf .line-height-125 * {
  line-height: 1.25em !important;
}
.pdf .line-height-20 * {
  line-height: 2em !important;
}
.pdf .p-30 {
  padding: 30px;
}
.pdf .p-0 {
  padding: 0px 0px 0px 0px;
}
.pdf .px-30 {
  padding: 0px 30px;
}
.pdf .pb-20 {
  padding-bottom: 20px;
}
.pdf .mt-0 {
  margin-top: 0px;
}
.pdf .mb-0 {
  margin-bottom: 0px;
}
.pdf .m-0 {
  margin: 0px;
}
.pdf .row.edited {
  padding-top: 40px;
}
.pdf .logo h1 {
  font-size: 1em;
}
.pdf .edited p:empty,
.pdf .edited span:empty {
  border: 1px dotted #ccc;
  min-height: 20px;
}
.pdf .editor a,
.pdf .editor .fa:before {
  font-size: 15px;
  padding: 2px 0px;
  display: inline-block;
}
.pdf .image-editor {
  padding: 0px 5px;
  background: #f9f9f9;
  left: calc(50% - 30px);
  top: calc(50% - 30px);
  z-index: 9999;
  text-align: center;
}
.pdf .colors {
  position: absolute;
  left: 2px;
  bottom: -8px;
  display: inline-block;
}
.pdf .colors .color {
  cursor: pointer;
  width: 15px;
  height: 15px;
  display: inline-block;
}
.pdf .row > .content-editor {
  position: absolute;
  width: 100%;
  text-align: left;
  padding: 5px;
  background: #eee;
  left: 0px;
  z-index: 9999;
  box-shadow: 0px 0px 2px #ccc;
  background: #ffffff;
  min-width: 80px;
  top: -35px;
  height: 35px;
  line-height: 15px;
}
.pdf .content-editor {
  position: absolute;
  width: 100%;
  text-align: left;
  padding: 5px;
  background: #fff;
  left: 0px;
  z-index: 9999;
  box-shadow: 0px 0px 2px #ccc;
  min-width: 80px;
  top: -35px;
  height: 35px;
  line-height: 15px;
}
.pdf .content-editor .text-editor a {
  font-size: 10px;
}
.pdf .tippek-container {
  box-shadow: 0px 0px 10px #ccc;
  background: #fff;
  padding: 15px;
  transition: all 0.5s;
}
.pdf .tippek-container .konyv {
  border-top: 4px double lightgrey;
}
.pdf .tippek-container .cv-details-text {
  line-height: 1.5;
}
@media (min-width: 1200px) {
  .pdf .tippek-container {
    position: fixed;
    z-index: 2;
    right: 0px;
    padding: 0px;
    width: 25%;
    padding: 25px 10px;
    margin-right: 1%;
  }
  .pdf .tippek-container .cont {
    height: calc(100% - 160px);
    overflow-y: scroll;
    overflow-x: hidden;
    width: 100% !important;
    margin: 0px;
  }
  .pdf .tippek-container .cont p {
    line-height: normal;
  }
  .pdf .tippek-container .cont::-webkit-scrollbar {
    width: 8px;
  }
  .pdf .tippek-container .cont::-webkit-scrollbar-thumb {
    background-color: darkgrey;
    outline: 1px solid slategrey;
  }
}
.pdf .copy.edited {
  overflow: hidden;
  padding-top: 80px;
}
.pdf .copy.edited .content-editor {
  top: 0px;
}
.pdf .copy .p span,
.pdf .copy p span,
.pdf .copy h1 span,
.pdf .copy h2 span,
.pdf .copy h3 span,
.pdf .copy h4 span,
.pdf .copy h5 span {
  width: 100%;
  display: block;
  word-wrap: break-word;
}
.pdf #contentarea .row:first-child.edited {
  margin-top: 30px;
}
.pdf .ui-sortable-handle:empty {
  min-height: 30px;
  border: 1px solid #fff0ef;
}
.pdf .edited {
  position: relative;
  border: 1px dotted #ccc;
  display: block;
}
.pdf .edited.row,
.pdf hr.edited,
.pdf p.edited:empty {
  display: block !important;
}
.pdf .text-editor {
  display: none;
  background: #fff;
}
.pdf .text-editor a {
  margin: 0px 2px;
}
.pdf hr.bg-kek,
.pdf hr.bg-fekete {
  height: 1px;
  width: 100%;
  border: none;
  margin-top: 1em !important;
  margin-bottom: 1em !important;
}
.pdf hr.bg-kek-bak {
  margin-top: 2em !important;
  margin-bottom: 2em !important;
}
.pdf .edited-cont {
  border: 1px solid #ccc;
  background: rgba(240, 248, 255, 0.1);
  padding-top: 30px;
}
.pdf .edited-cont .text-editor {
  display: block;
  margin: -5px -5px;
  padding: 4px;
}
.pdf .edited-cont p,
.pdf .edited-cont div,
.pdf .edited-cont h1,
.pdf .edited-cont h2,
.pdf .edited-cont h3,
.pdf .edited-cont h4 {
  margin-top: 0px;
  padding-top: 0px;
}
.pdf .edited-cont .edited-cont {
  border: 1px dotted #eee;
}
.pdf .ui-sortable-helper {
  width: 1000px;
}
.pdf #contentarea .container {
  size: 7in 9.25in;
  margin: 40px auto;
  width: 850px;
  height: 1308px;
  box-shadow: 0px 0px 60px #ccc;
  overflow: hidden;
  background: #fff;
}
@media (max-width: 1850px) {
  .pdf #contentarea .container {
    margin: 40px 10px;
  }
}
.pdf .overflow-hidden {
  overflow: hidden !important;
}
.pdf .overflow {
  overflow: visible !important;
}
.pdf .row-eq-height,
.pdf .row-eq-height.edited {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex !important;
}
.pdf a {
  color: #337ab7;
}
.pdf .loader {
  position: fixed;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.9);
  top: 0px;
  left: 0px;
  z-index: 99999;
  color: red;
  padding: 25% 50%;
  font-size: 13em;
}
.pdf .loader i {
  opacity: 0.4;
}
.pdf .pdf-menu .sablonok {
  padding-top: 3px;
  height: 90px;
}
.pdf .pdf-menu .sablonok a {
  width: 60px;
  display: inline-block;
  border: 1px solid;
  position: relative;
}
.pdf .pdf-menu .sablonok .active {
  border: 2px solid red;
}
.pdf .pdf-menu .buttons {
  float: left;
  margin-right: 10px;
}
@media (max-width: 992px) {
  .pdf .pdf-menu .buttons {
    text-align: center;
  }
}
.pdf .pdf-menu .buttons .bblock {
  display: inline-block;
  padding: 10px 20px;
  text-align: center;
  color: #fff;
  font-size: 0.8em;
  background: linear-gradient(#272727, #656565);
  height: 90px;
  vertical-align: bottom;
  line-height: normal;
}
.pdf .pdf-menu .buttons .bblock i {
  font-size: 2em;
  padding: 5px;
}
.pdf .pdf-menu .buttons .bblock:hover {
  background: #909090;
}
.pdf .pdf-menu .buttons .bblock a {
  color: #fff;
}
@media (max-width: 992px) {
  .pdf .pdf-menu .buttons .bblock {
    width: 24%;
  }
}
.pdf .tartalom,
.pdf .fejlec {
  position: relative;
  z-index: 1;
}
.pdf .tartalom div ul li {
  font-size: 1em !important;
}
.pdf .menu-toolbar {
  height: 90px;
}
.pdf .kokonyv {
  line-height: normal;
  text-align: center;
  margin: 20px;
  background: #fff;
  top: 0px;
  border-bottom: 1px solid #ccc;
}
.pdf .kokonyv img {
  margin: -10px -20px;
}
.pdf .open-toggle {
  color: red;
  cursor: pointer;
  clear: both;
}
.pdf .open-toggle:before {
  content: "\f055";
  font-family: FontAwesome;
  margin-right: 10px;
  float: left;
  /*height: 40px;*/
}
.pdf .tippek-close {
  float: left;
  position: relative;
  top: -10px;
  left: 0px;
  cursor: pointer;
  color: red;
}
@media (max-width: 992px) {
  .pdf .tippek-close {
    display: none;
  }
}
.pdf iframe.pdf {
  display: none;
}
#merleg-sablon-hu,
#merleg-sablon-de,
#merleg-sablon-en {
  font-family: 'Times New Roman';
}
#merleg-sablon-hu .fejlec .col-xs-5 p span:nth-child(1),
#merleg-sablon-de .fejlec .col-xs-5 p span:nth-child(1),
#merleg-sablon-en .fejlec .col-xs-5 p span:nth-child(1) {
  font-family: 'Calibri';
  font-weight: 500;
}
#merleg-sablon-hu .fejlec .col-xs-5 p span:nth-child(2),
#merleg-sablon-de .fejlec .col-xs-5 p span:nth-child(2),
#merleg-sablon-en .fejlec .col-xs-5 p span:nth-child(2) {
  font-style: italic;
}
#merleg-sablon-hu .tartalom > .row:nth-child(odd),
#merleg-sablon-de .tartalom > .row:nth-child(odd),
#merleg-sablon-en .tartalom > .row:nth-child(odd) {
  background: #fff;
}
#merleg-sablon-hu .tartalom > .row:nth-child(even),
#merleg-sablon-de .tartalom > .row:nth-child(even),
#merleg-sablon-en .tartalom > .row:nth-child(even) {
  background: #ececec;
}
#merleg-sablon-hu .szin1.tartalom > .row:nth-child(odd),
#merleg-sablon-de .szin1.tartalom > .row:nth-child(odd),
#merleg-sablon-en .szin1.tartalom > .row:nth-child(odd) {
  background: #fff;
}
#merleg-sablon-hu .szin1.tartalom > .row:nth-child(even),
#merleg-sablon-de .szin1.tartalom > .row:nth-child(even),
#merleg-sablon-en .szin1.tartalom > .row:nth-child(even) {
  background: #c0e2ca;
}
#merleg-sablon-hu .szin2.tartalom > .row:nth-child(odd),
#merleg-sablon-de .szin2.tartalom > .row:nth-child(odd),
#merleg-sablon-en .szin2.tartalom > .row:nth-child(odd) {
  background: #fff;
}
#merleg-sablon-hu .szin2.tartalom > .row:nth-child(even),
#merleg-sablon-de .szin2.tartalom > .row:nth-child(even),
#merleg-sablon-en .szin2.tartalom > .row:nth-child(even) {
  background: #d4e0e0;
}
#merleg-sablon-hu .font2,
#merleg-sablon-de .font2,
#merleg-sablon-en .font2,
#merleg-sablon-hu h2,
#merleg-sablon-de h2,
#merleg-sablon-en h2 {
  font-family: 'Calibri';
  font-weight: 500;
  letter-spacing: 5px;
}
#merleg-sablon-hu .font2,
#merleg-sablon-de .font2,
#merleg-sablon-en .font2 {
  font-size: 1.5em;
}
#merleg-sablon-hu .merleg-cv-contact,
#merleg-sablon-de .merleg-cv-contact,
#merleg-sablon-en .merleg-cv-contact {
  width: inherit;
  display: initial;
}
#skorpio-sablon-hu,
#skorpio-sablon-de,
#skorpio-sablon-en,
.skorpio-motiv {
  font-family: 'Armata';
}
#skorpio-sablon-hu h1,
#skorpio-sablon-de h1,
#skorpio-sablon-en h1,
.skorpio-motiv h1,
#skorpio-sablon-hu h2,
#skorpio-sablon-de h2,
#skorpio-sablon-en h2,
.skorpio-motiv h2,
#skorpio-sablon-hu h3,
#skorpio-sablon-de h3,
#skorpio-sablon-en h3,
.skorpio-motiv h3,
#skorpio-sablon-hu h4,
#skorpio-sablon-de h4,
#skorpio-sablon-en h4,
.skorpio-motiv h4 {
  font-family: 'Armata';
}
#skorpio-sablon-hu .p,
#skorpio-sablon-de .p,
#skorpio-sablon-en .p,
.skorpio-motiv .p,
#skorpio-sablon-hu p,
#skorpio-sablon-de p,
#skorpio-sablon-en p,
.skorpio-motiv p,
#skorpio-sablon-hu li,
#skorpio-sablon-de li,
#skorpio-sablon-en li,
.skorpio-motiv li,
#skorpio-sablon-hu td,
#skorpio-sablon-de td,
#skorpio-sablon-en td,
.skorpio-motiv td {
  font-family: 'Calibri';
}
#halak-sablon-hu,
#halak-sablon-de,
#halak-sablon-en,
.halak-motiv,
.merleg-motiv {
  font-family: 'Times New Roman';
}
#halak-sablon-hu h1,
#halak-sablon-de h1,
#halak-sablon-en h1,
.halak-motiv h1,
.merleg-motiv h1,
#halak-sablon-hu h2,
#halak-sablon-de h2,
#halak-sablon-en h2,
.halak-motiv h2,
.merleg-motiv h2,
#halak-sablon-hu h3,
#halak-sablon-de h3,
#halak-sablon-en h3,
.halak-motiv h3,
.merleg-motiv h3 {
  font-family: Vollkorn;
}
#halak-sablon-hu .halak-cv-contact,
#halak-sablon-de .halak-cv-contact,
#halak-sablon-en .halak-cv-contact,
.halak-motiv .halak-cv-contact,
.merleg-motiv .halak-cv-contact {
  width: inherit;
  display: initial;
}
#oroszlan-sablon-hu,
#oroszlan-sablon-de,
#oroszlan-sablon-en,
.oroszlan-motiv {
  font-family: 'Calibri';
}
#oroszlan-sablon-hu h1,
#oroszlan-sablon-de h1,
#oroszlan-sablon-en h1,
.oroszlan-motiv h1,
#oroszlan-sablon-hu h2,
#oroszlan-sablon-de h2,
#oroszlan-sablon-en h2,
.oroszlan-motiv h2,
#oroszlan-sablon-hu h3,
#oroszlan-sablon-de h3,
#oroszlan-sablon-en h3,
.oroszlan-motiv h3,
#oroszlan-sablon-hu h4,
#oroszlan-sablon-de h4,
#oroszlan-sablon-en h4,
.oroszlan-motiv h4,
#oroszlan-sablon-hu a,
#oroszlan-sablon-de a,
#oroszlan-sablon-en a,
.oroszlan-motiv a {
  font-family: 'Calibri';
}
#ikrek-sablon-hu,
#ikrek-sablon-de,
#ikrek-sablon-en,
.ikrek-motiv {
  font-family: 'Armata';
}
#ikrek-sablon-hu h1,
#ikrek-sablon-de h1,
#ikrek-sablon-en h1,
.ikrek-motiv h1,
#ikrek-sablon-hu h2,
#ikrek-sablon-de h2,
#ikrek-sablon-en h2,
.ikrek-motiv h2,
#ikrek-sablon-hu h3,
#ikrek-sablon-de h3,
#ikrek-sablon-en h3,
.ikrek-motiv h3,
#ikrek-sablon-hu h4,
#ikrek-sablon-de h4,
#ikrek-sablon-en h4,
.ikrek-motiv h4,
#ikrek-sablon-hu a,
#ikrek-sablon-de a,
#ikrek-sablon-en a,
.ikrek-motiv a {
  font-family: 'Armata';
}
#vizonto-sablon-hu,
#vizonto-sablon-de,
#vizonto-sablon-en,
.vizonto-motiv {
  font-family: 'Times New Roman';
}
#vizonto-sablon-hu h1,
#vizonto-sablon-de h1,
#vizonto-sablon-en h1,
.vizonto-motiv h1 {
  margin-top: 100px;
}
#vizonto-sablon-hu h1,
#vizonto-sablon-de h1,
#vizonto-sablon-en h1,
.vizonto-motiv h1,
#vizonto-sablon-hu h3,
#vizonto-sablon-de h3,
#vizonto-sablon-en h3,
.vizonto-motiv h3 {
  font-family: 'bebas_neueregular';
}
#bika-sablon-hu,
#bika-sablon-de,
#bika-sablon-en,
.bika-motiv {
  font-family: 'Source Sans Pro';
}
#bika-sablon-hu h2,
#bika-sablon-de h2,
#bika-sablon-en h2,
.bika-motiv h2 {
  font-family: Sans-Serif;
}
#nyilas-sablon-hu h1 {
  font-size: 28px;
}
#nyilas-sablon-hu h3 {
  font-size: 21px;
}
#nyilas-sablon-hu .p {
  font-size: 12px;
}
#nyilas-sablon-hu .nyilas-poz {
  font-size: 16px !important;
}
#nyilas-sablon-hu h1,
#nyilas-sablon-de h1,
#nyilas-sablon-en h1,
.nyilas-motiv h1,
#nyilas-sablon-hu h3,
#nyilas-sablon-de h3,
#nyilas-sablon-en h3,
.nyilas-motiv h3 {
  font-family: Calibri;
  letter-spacing: 5px;
  font-weight: 500;
}
#nyilas-sablon-hu .p,
#nyilas-sablon-de .p,
#nyilas-sablon-en .p,
.nyilas-motiv .p,
#nyilas-sablon-hu div p,
#nyilas-sablon-de div p,
#nyilas-sablon-en div p,
.nyilas-motiv div p,
#nyilas-sablon-hu li,
#nyilas-sablon-de li,
#nyilas-sablon-en li,
.nyilas-motiv li {
  font-family: Verdana;
}
#kos-sablon-hu div p,
#kos-sablon-de div p,
#kos-sablon-en div p,
.kos-motiv div p,
.rak-motiv div p,
#kos-sablon-hu blockquote,
#kos-sablon-de blockquote,
#kos-sablon-en blockquote,
.kos-motiv blockquote,
.rak-motiv blockquote,
#kos-sablon-hu li,
#kos-sablon-de li,
#kos-sablon-en li,
.kos-motiv li,
.rak-motiv li,
#kos-sablon-hu .p,
#kos-sablon-de .p,
#kos-sablon-en .p,
.kos-motiv .p,
.rak-motiv .p {
  font-family: Verdana;
}
#kos-sablon-hu li,
#szuz-sablon-hu li,
#merleg-sablon-hu li,
#vizonto-sablon-hu li {
  font-size: 17.12px !important;
}
#szuz-sablon-hu ul li {
  font-family: "Arial" !important;
}
#rak-sablon-hu div p,
#rak-sablon-de div p,
#rak-sablon-en div p,
#rak-sablon-hu blockquote,
#rak-sablon-de blockquote,
#rak-sablon-en blockquote,
#rak-sablon-hu li,
#rak-sablon-de li,
#rak-sablon-en li,
#rak-sablon-hu .p,
#rak-sablon-de .p,
#rak-sablon-en .p {
  font-family: Verdana;
  font-size: inherit;
}
#rak-sablon-hu .rak-cv-contact,
#rak-sablon-de .rak-cv-contact,
#rak-sablon-en .rak-cv-contact {
  width: inherit;
  display: initial;
}
#szuz-sablon-hu div p,
#szuz-sablon-de div p,
#szuz-sablon-en div p,
#szuz-sablon-hu blockquote,
#szuz-sablon-de blockquote,
#szuz-sablon-en blockquote,
#szuz-sablon-hu h3,
#szuz-sablon-de h3,
#szuz-sablon-en h3,
#szuz-sablon-hu h4,
#szuz-sablon-de h4,
#szuz-sablon-en h4,
#szuz-sablon-hu span,
#szuz-sablon-de span,
#szuz-sablon-en span,
#szuz-sablon-hu .p,
#szuz-sablon-de .p,
#szuz-sablon-en .p {
  font-family: "Arial" !important;
}
#szuz-sablon-hu .szin,
#szuz-sablon-de .szin,
#szuz-sablon-en .szin {
  background: #fff;
  padding: 0px 20px;
}
#szuz-sablon-hu .vonal,
#szuz-sablon-de .vonal,
#szuz-sablon-en .vonal {
  width: 100%;
  border-bottom: 1px solid #000;
}
#szuz-sablon-hu .masked-bg,
#szuz-sablon-de .masked-bg,
#szuz-sablon-en .masked-bg {
  background: white;
  width: inherit;
  display: inline-block;
}
.szuz-motiv {
  font-family: "Arial";
}
.bak-motiv {
  font-family: 'arconregular';
}
#bak-sablon-hu,
#bak-sablon-en,
#bak-sablon-de {
  position: relative;
  font-family: 'arconregular';
}
#bak-sablon-hu .tartalom .bg-feher,
#bak-sablon-en .tartalom .bg-feher,
#bak-sablon-de .tartalom .bg-feher {
  color: #a0a5ab;
}
#bak-sablon-hu .tartalom .col-xs-3,
#bak-sablon-en .tartalom .col-xs-3,
#bak-sablon-de .tartalom .col-xs-3 {
  padding-top: 15px;
}
#bak-sablon-hu .tartalom .col-xs-3 .vonal,
#bak-sablon-en .tartalom .col-xs-3 .vonal,
#bak-sablon-de .tartalom .col-xs-3 .vonal {
  position: absolute;
  right: 0px;
  top: 32px;
  width: 50%;
  border-bottom: 2px solid #fff;
  height: 2px;
}
#bak-sablon-hu .left-bg,
#bak-sablon-en .left-bg,
#bak-sablon-de .left-bg {
  position: absolute;
  top: 40px;
  background: #ececec;
  height: 1308px;
  width: 213px;
  margin-left: -15px;
}
#varazslo-sablon-hu,
#varazslo-sablon-en,
#varazslo-sablon-de {
  border: 15px solid #fff;
  font-family: 'Montserrat', sans-serif !important;
}
#varazslo-sablon-hu .fejlec h2,
#varazslo-sablon-en .fejlec h2,
#varazslo-sablon-de .fejlec h2,
#varazslo-sablon-hu .fejlec h3,
#varazslo-sablon-en .fejlec h3,
#varazslo-sablon-de .fejlec h3 {
  font-family: 'Montserrat', sans-serif !important;
}
#varazslo-sablon-hu .fejlec h3,
#varazslo-sablon-en .fejlec h3,
#varazslo-sablon-de .fejlec h3 {
  font-size: 1.5em !important;
}
#varazslo-sablon-hu .fejlec hr,
#varazslo-sablon-en .fejlec hr,
#varazslo-sablon-de .fejlec hr {
  margin: 0px !important;
  border: 0;
  height: 2px;
  background: white;
}
#varazslo-sablon-hu .fejlec .iconbox,
#varazslo-sablon-en .fejlec .iconbox,
#varazslo-sablon-de .fejlec .iconbox {
  float: left;
  border: 2px solid;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  text-align: center;
  margin-right: 10px;
}
#varazslo-sablon-hu .fejlec .icon,
#varazslo-sablon-en .fejlec .icon,
#varazslo-sablon-de .fejlec .icon {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}
#varazslo-sablon-hu .tartalom h2,
#varazslo-sablon-en .tartalom h2,
#varazslo-sablon-de .tartalom h2,
#varazslo-sablon-hu .tartalom h3,
#varazslo-sablon-en .tartalom h3,
#varazslo-sablon-de .tartalom h3 {
  font-family: 'Montserrat', sans-serif !important;
}
#varazslo-sablon-hu .tartalom h3,
#varazslo-sablon-en .tartalom h3,
#varazslo-sablon-de .tartalom h3 {
  font-size: 1.5em;
}
#varazslo-sablon-hu .tartalom .nevtabla,
#varazslo-sablon-en .tartalom .nevtabla,
#varazslo-sablon-de .tartalom .nevtabla {
  text-align: center;
  padding: 30px 0px;
  line-height: 1 !important;
}
#varazslo-sablon-hu .tartalom .nevtabla hr,
#varazslo-sablon-en .tartalom .nevtabla hr,
#varazslo-sablon-de .tartalom .nevtabla hr {
  width: 50%;
  margin: 0 auto !important;
  height: 2px;
  background: white;
  border: 0;
}
#varazslo-sablon-hu .bg-kszurke,
#varazslo-sablon-en .bg-kszurke,
#varazslo-sablon-de .bg-kszurke {
  background-color: #737373;
}
#jelentkezo-sablon-hu {
  background: #f1f1f1 !important;
}
#jelentkezo-sablon-hu h3.mszin1 {
  color: #63995a !important;
}
#jelentkezo-sablon-hu .fejlec h2 {
  font-family: 'Oswald', sans-serif;
  font-size: 2.5em !important;
  margin-bottom: 0;
}
#jelentkezo-sablon-hu .fejlec h3 {
  margin-top: 0;
}
#jelentkezo-sablon-hu .fejlec .headline {
  height: 25px;
}
#jelentkezo-sablon-hu .fejlec .iconbox {
  float: left;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  text-align: center;
  margin-right: 10px;
  color: #fff !important;
}
#jelentkezo-sablon-hu .fejlec .arckep {
  border-radius: 50%;
  border: 2px solid;
  margin-top: 20px;
}
#jelentkezo-sablon-hu .fejlec .visible-pdf {
  display: none;
}
#jelentkezo-sablon-hu .tartalom h2,
#jelentkezo-sablon-hu .tartalom h3 {
  font-family: 'Oswald', sans-serif;
}
#remenyi-sablon-hu .p,
#remenyi-sablon-hu li {
  line-height: 1 !important;
  font-family: "Times New Roman";
}
#remenyi-sablon-hu .fejlec h2,
#remenyi-sablon-hu .fejlec h3 {
  font-family: 'Anton', sans-serif;
}
#remenyi-sablon-hu .fejlec .iconbox {
  width: 30px;
  float: left;
}
#remenyi-sablon-hu .tartalom h2,
#remenyi-sablon-hu .tartalom h3 {
  font-family: 'Anton', sans-serif;
}
#lehengerlo-sablon-hu {
  font-family: 'Roboto', sans-serif !important;
}
#lehengerlo-sablon-hu .p {
  line-height: 1 !important;
  text-align: justify;
  font-size: 1em;
}
#lehengerlo-sablon-hu hr {
  margin: 0!important;
  height: 2px;
  border: 0;
}
#lehengerlo-sablon-hu .fejlec .f1 {
  overflow: hidden;
  margin-bottom: -78px;
}
#lehengerlo-sablon-hu .fejlec .iconbox {
  width: 50px;
  float: left;
}
#lehengerlo-sablon-hu .fejlec .nev-wrapper {
  padding: 20px;
  position: absolute;
  bottom: 0;
  width: 100%;
}
#lehengerlo-sablon-hu .tartalom {
  background: #e6e6e6;
  padding: 20px;
  height: 70%;
}
#lehengerlo-sablon-hu .tartalom h3 {
  margin: 0.7em 0 0.4em 0;
}
#lehengerlo-sablon-hu .icon {
  height: 47px;
  line-height: 47px;
}
.pdf.print {
  padding-bottom: 0px;
}
.pdf.print #szuz-sablon-hu {
  font-family: Arial !important;
}
.pdf.print #varazslo-sablon-hu .visible-pdf {
  display: block!important;
}
.pdf.print #jelentkezo-sablon-hu {
  font-family: 'Oswald', sans-serif !important;
}
.pdf.print #jelentkezo-sablon-hu .arckep {
  border: none!important;
}
.pdf.print #jelentkezo-sablon-hu .visible-pdf {
  display: block!important;
}
.pdf.print #remenyi-sablon-hu {
  font-family: Arial !important;
}
.pdf.print .container {
  border: none !important;
}
.pdf.print .tippek-container {
  display: none;
}
.pdf.print #contentarea .container {
  height: 1308px;
  margin-bottom: 5px;
  overflow: hidden;
  size: inherit !important;
  margin: inherit !important;
  max-width: inherit !important;
  box-shadow: inherit !important;
  position: relative;
}
.pdf.print #contentarea .container:after {
  content: "WWW.ONELETRAJZSABLON.HU";
  font-size: 1em !important;
  font-family: Arial !important;
  opacity: 0.2 !important;
  bottom: 0 !important;
  left: 70% !important;
  position: absolute !important;
}
footer {
  color: #999;
  background: #161616;
  position: absolute;
  bottom: 0;
  padding: 10px;
  width: 100%;
  height: auto;
}
@media (max-width: 480px) {
  body {
    padding-bottom: 70px;
  }
}

/*# sourceMappingURL=main.css.map */
