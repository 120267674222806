@import url("/editor/css/fontello.css"); /* backward compatible */
@import url("/editor/css/ionicons.min.css");


/**********************************
    Adjustment for Default
***********************************/


/*.container > .row, .container > div > .row {padding-top:10px;padding-bottom:10px}*/


/**********************************
    General
***********************************/

html { font-size: 100%; position: relative}
body {
    margin: 0;
    font-family: "Open Sans", sans-serif;
    font-size: 100%;
    line-height: 2;
    font-weight: 300;
}
.p, p, td, li, label {

    font-size: 1.07em;
    line-height: 2;
    font-weight: 300;
}
h1, h2, h3, h4, h5, h6 {
    font-family: "Open Sans", sans-serif;
    font-weight: 300;
    letter-spacing: 0px;
    line-height: 1.4;
}

h1 {font-size: 2.36em;margin:0.4em 0;}
h2 {font-size: 2em;margin:0.6em 0;}
h3 {font-size: 1.73em;margin:0.7em 0;}
h4 {font-size: 1.6em;margin:0.8em 0;}
h5 {font-size: 1.48em;margin:0.8em 0;}
h6 {font-size: 1.3em;margin:0.8em 0;}
.p,p {margin:1em 0;}

.display { margin-bottom: 0.5em;  }
.display h1 {
    font-weight: 800;
    font-size: 3em;
    line-height:1.4;
    text-transform: uppercase;
}
.display p {
    font-size: 1.3em;
    font-style: italic;
}

@media all and (max-width: 1024px) {
    h1 {font-size: 2em;}
    h2 {font-size: 1.73em;}
    h3 {font-size: 1.6em;}
    h4 {font-size: 1.48em;}
    h5 {font-size: 1.3em;font-weight:bold;}
    h6 {font-size: 1em;font-weight:bold;}
    .display h1 { font-size: 2.2em; }
    .display p { font-size: 1.1em; }
}

a {color: #009E91;}
hr {border:none;border-top: rgba(0, 0, 0, 0.18) 1px solid;margin: 2em 0 !important;}
img {max-width:100%;}
figure {margin:0}
ol, ul {line-height: inherit; font-weight: inherit;}


/**********************************
    Grid
***********************************/

@media (min-width: 40rem) {
    .column {
        float: left;
        padding-left: 1rem; /* beta3 */
        padding-right: 1rem; /* beta3 */
        -moz-box-sizing: border-box;
        -webkit-box-sizing: border-box;
        box-sizing: border-box;
    }
    .column.full { width: 100%; }
    .column.two-third { width: 66.7%; }
    .column.two-fourth { width: 75%; }
    .column.two-fifth { width: 80%; }
    .column.two-sixth { width: 83.3%; }
    .column.half { width: 50%; }
    .column.third { width: 33.3%; }
    .column.fourth { width: 25%; }
    .column.fifth { width: 20%; }
    .column.sixth { width: 16.6%; }
    .column.flow-opposite { float: right; }
}
.clearfix:before, .clearfix:after {content: " ";display: table;}
.clearfix:after {clear: both;}
.clearfix {*zoom: 1;}


/**********************************
    Elements
***********************************/

.center {text-align:center}
.right {text-align:right}
.left {text-align:left}
.padding-20 {padding:20px}
.padding-25 {padding:25px}
.padding-30 {padding:30px}
.padding-35 {padding:35px}
.padding-40 {padding:40px}
@media all and (max-width: 540px) {
    .center {text-align:initial}
    .right {text-align:initial}
    .left {text-align:initial}
    /*.padding-20 {padding:0}*/
    /*.padding-25 {padding:0}*/
    /*.padding-30 {padding:0}*/
    /*.padding-35 {padding:0}*/
    /*.padding-40 {padding:0}*/
}

.margin-0 {margin:0 !important}
.margin-20 {margin:20px !important}
.margin-25 {margin:25px !important}
.margin-30 {margin:30px !important}
.margin-35 {margin:35px !important}
.margin-40 {margin:40px !important}

.margint-0 {margin:0 !important}
.margint-20 {margin:20px 0px !important}
.margint-25 {margin:25px 0px !important}
.margint-30 {margin:30px 0px !important}
.margint-35 {margin:35px 0px !important}
.margint-40 {margin:40px 0px !important}

.is-card { display:table; background-color:#fff;  }
.is-card > * { display:table; }
.is-card-circle { width:280px; height: 280px; border-radius:500px; padding:70px; margin:0 auto; }
@media all and (max-width: 540px) {
    .is-card-circle { zoom:0.7; -moz-transform: scale(0.7); }
}
.is-card-content-centered { display:table-cell;vertical-align:middle;text-align:center; }
.max-390 { max-width:390px;margin:0 auto; }
.shadow-1 { /* card */
    -webkit-box-shadow: 0 1px 3px rgba(0,0,0, 0.12), 0 1px 2px rgba(0,0,0, 0.24);
    -moz-box-shadow: 0 1px 3px rgba(0,0,0, 0.12), 0 1px 2px rgba(0,0,0, 0.24);
    box-shadow: 0 1px 3px rgba(0,0,0, 0.12), 0 1px 2px rgba(0,0,0, 0.24);
}
.shadow-2 { /* screenshot */
    -webkit-box-shadow: 0 30px 50px rgba(0, 0, 0, 0.15);
    -moz-box-shadow: 0 30px 50px rgba(0, 0, 0, 0.15);
    box-shadow: 0 30px 50px rgba(0, 0, 0, 0.15);
}
.shadow-3 { /* highlight */
    -webkit-box-shadow: 0 0px 100px rgba(0, 0, 0, 0.2);
    -moz-box-shadow: 0 0px 100px rgba(0, 0, 0, 0.2);
    box-shadow: 0 0px 100px rgba(0, 0, 0, 0.2);
}

img.circle {border-radius:500px;margin-top:0;}
img.bordered {border: #ccc 1px solid;}

.embed-responsive {position: relative;display:block;height:0;padding:0;overflow:hidden;margin-top: 1.4em;margin-bottom: 1em;}
.embed-responsive.embed-responsive-16by9 {padding-bottom: 56.25%;}
.embed-responsive.embed-responsive-4by3 {padding-bottom: 75%;}
.embed-responsive iframe {position: absolute;top:0;bottom:0;left:0;width:100%;height:100%;border:0;}

.list {position:relative;margin:1.5em 0;}
.list > i {position:absolute;left:-3px;top:7px;font-size:1.7em;line-height:1;}
.list > h2, .list > h3 {margin: 0 0 0 50px;}
.list > p {margin: 5px 0 0 50px}

.quote {position:relative;margin:1.5em 0;}
.quote > i {position: absolute;top: -10px; left: -7px;font-size: 2em;}
.quote > small {margin-left:50px;opacity: 0.7;font-size: 1em;}
.quote > p {margin-left:50px;font-size: 1.5em;}
@media all and (max-width: 540px) {
    .quote > i {left: -15px;font-size:1.5em;}
    .quote > small {margin-left:20px;ont-size: 1em;}
    .quote > p {margin-left:20px;font-size: 1.2em;}
}

.is-social {line-height:1;margin-bottom:1.5em}
.is-social a > i {text-decoration:none;color:#333;font-size:1.2em;margin:0 15px 0 0;-webkit-transition: all 0.1s ease-in-out;transition: all 0.1s ease-in-out;}
.is-social a:hover > i {color:#08c9b9;}
.is-light-text .is-social a > i {color:#fff}
.is-light-text .is-social a:hover > i {color:#fff}
.is-dark-text .is-social a > i {color:#000}
.is-dark-text .is-social a:hover > i {color:#000}

/* backward compatible */
.social {line-height:1;margin-bottom:1.5em}
.social a > i {text-decoration:none;color:#333;font-size:1.2em;margin:0 15px 0 0;-webkit-transition: all 0.1s ease-in-out;transition: all 0.1s ease-in-out;}
.social a:hover > i {color:#08c9b9;}
.is-light-text .social a > i {color:#fff}
.is-light-text .social a:hover > i {color:#fff}

.is-rounded-button-big {display:inline-block;}
.is-rounded-button-big a {display:table;float:left;text-align:center;margin:0 20px;border-radius: 500px; width: 110px; height: 110px;background-color: #aaa;}
.is-rounded-button-big a i {display:table-cell;width:100%;height:100%;vertical-align:middle;color:#ffffff;font-size:40px}
.is-rounded-button-big a:first-child {margin:0 20px 0 0;}
.is-rounded-button-big a:last-child {margin:0 0 0 20px;}

.is-rounded-button-medium {display:inline-block;}
.is-rounded-button-medium a {display:table;float:left;text-align:center;margin:0 20px;border-radius: 500px; width: 70px; height: 70px;background-color: #aaa;}
.is-rounded-button-medium a i {display:table-cell;width:100%;height:100%;vertical-align:middle;color:#ffffff;font-size:30px}

.is-boxed-button-big {display:inline-block;}
.is-boxed-button-big a {display:table;float:left;text-align:center;margin:0 30px 0 0;width: 110px; height: 110px;background-color: #aaa;}
.is-boxed-button-big a i {display:table-cell;width:100%;height:100%;vertical-align:middle;color:#ffffff;font-size:40px}

.is-boxed-button-big2 {display:inline-block;}
.is-boxed-button-big2 a {display:table;float:left;text-align:center;margin:0 30px 0 0;width: 150px; height: 70px;background-color: #aaa;}
.is-boxed-button-big2 a i {display:table-cell;width:100%;height:100%;vertical-align:middle;color:#ffffff;font-size:35px}

.is-boxed-button-medium {display:inline-block;}
.is-boxed-button-medium a {display:table;float:left;text-align:center;margin:0 30px 0 0;width: 70px; height: 70px;background-color: #aaa;}
.is-boxed-button-medium a i {display:table-cell;width:100%;height:100%;vertical-align:middle;color:#ffffff;font-size:30px}

.is-boxed-button-medium2 {display:inline-block;}
.is-boxed-button-medium2 a {display:table;float:left;text-align:center;margin:0 30px 0 0;width: 100px; height: 50px;background-color: #aaa;}
.is-boxed-button-medium2 a i {display:table-cell;width:100%;height:100%;vertical-align:middle;color:#ffffff;font-size:25px}

.is-boxed-button-small {display:inline-block;}
.is-boxed-button-small a {display:table;float:left;text-align:center;margin:0 20px 0 0;width: 50px; height: 50px;background-color: #aaa;}
.is-boxed-button-small a i {display:table-cell;width:100%;height:100%;vertical-align:middle;color:#ffffff;font-size:20px}

.size-12 {font-size:12px}
.size-14 {font-size:14px}
.size-16 {font-size:16px}
.size-18 {font-size:18px}
.size-21 {font-size:21px}
.size-24 {font-size:24px}
.size-32 {font-size:32px}
.size-48 {font-size:48px}
.size-64 {font-size:64px}
.size-80 {font-size:80px}
.size-96 {font-size:96px}
@media all and (max-width: 1024px) {
    .size-12 {font-size:12px}
    .size-14 {font-size:14px}
    .size-16 {font-size:16px}
    .size-18 {font-size:18px}
    .size-21 {font-size:21px}
    .size-24 {font-size:24px}
    .size-32 {font-size:32px}
    .size-48 {font-size:48px}
    .size-64 {font-size:64px}
    .size-80 {font-size:80px}
    .size-96 {font-size:96px}
}


/**********************************
    Title Styles
***********************************/

.is-light-text * {color: #fff;}
.is-dark-text * {color: #000;}

/* Style 1 */

.is-title1-96 {margin-top:20px;margin-bottom:20px;}
.is-title1-80 {margin-top:15px;margin-bottom:15px;}
.is-title1-64 {margin-top:15px;margin-bottom:15px;}
.is-title1-48 {margin-top:15px;margin-bottom:15px;}
.is-title1-32 {margin-top:15px;margin-bottom:15px;}

/* Style 2 */

.is-title2-96 {margin-top:25px;margin-bottom:20px;}
.is-title2-80 {margin-top:20px;margin-bottom:15px;}
.is-title2-64 {margin-top:20px;margin-bottom:15px;}
.is-title2-48 {margin-top:15px;margin-bottom:15px;}
.is-title2-32 {margin-top:10px;margin-bottom:15px;}

/* Style 3 */

.is-title3-96 {margin-top:30px;margin-bottom:35px;padding:20px 0;border-top:#000 2px solid;border-bottom:#000 2px solid;}
.is-title3-80 {margin-top:25px;margin-bottom:33px;padding:20px 0;border-top:#000 2px solid;border-bottom:#000 2px solid;}
.is-title3-64 {margin-top:20px;margin-bottom:30px;padding:18px 0;border-top:#000 2px solid;border-bottom:#000 2px solid;}
.is-title3-48 {margin-top:20px;margin-bottom:25px;padding:18px 0;border-top:#000 2px solid;border-bottom:#000 2px solid;}
.is-title3-32 {margin-top:20px;margin-bottom:20px;padding:15px 0;border-top:#000 2px solid;border-bottom:#000 2px solid;}

.is-light-text .is-title3-96 {border-top:#fff 2px solid;border-bottom:#fff 2px solid;}
.is-light-text .is-title3-80 {border-top:#fff 2px solid;border-bottom:#fff 2px solid;}
.is-light-text .is-title3-64 {border-top:#fff 2px solid;border-bottom:#fff 2px solid;}
.is-light-text .is-title3-48 {border-top:#fff 2px solid;border-bottom:#fff 2px solid;}
.is-light-text .is-title3-32 {border-top:#fff 2px solid;border-bottom:#fff 2px solid;}

.is-dark-text .is-title3-96 {border-top:#000 2px solid;border-bottom:#000 2px solid;}
.is-dark-text .is-title3-80 {border-top:#000 2px solid;border-bottom:#000 2px solid;}
.is-dark-text .is-title3-64 {border-top:#000 2px solid;border-bottom:#000 2px solid;}
.is-dark-text .is-title3-48 {border-top:#000 2px solid;border-bottom:#000 2px solid;}
.is-dark-text .is-title3-32 {border-top:#000 2px solid;border-bottom:#000 2px solid;}

/* Style 4 */

.is-title4-96 {margin-top:30px;margin-bottom:35px;padding:20px 30px;border:#000 2px solid;}
.is-title4-80 {margin-top:25px;margin-bottom:33px;padding:20px 30px;border:#000 2px solid;}
.is-title4-64 {margin-top:20px;margin-bottom:30px;padding:18px 28px;border:#000 2px solid;}
.is-title4-48 {margin-top:20px;margin-bottom:25px;padding:18px 25px;border:#000 2px solid;}
.is-title4-32 {margin-top:20px;margin-bottom:20px;padding:15px 20px;border:#000 2px solid;}

.is-light-text .is-title4-96 {border:#fff 2px solid;}
.is-light-text .is-title4-80 {border:#fff 2px solid;}
.is-light-text .is-title4-64 {border:#fff 2px solid;}
.is-light-text .is-title4-48 {border:#fff 2px solid;}
.is-light-text .is-title4-32 {border:#fff 2px solid;}

.is-dark-text .is-title4-96 {border:#000 2px solid;}
.is-dark-text .is-title4-80 {border:#000 2px solid;}
.is-dark-text .is-title4-64 {border:#000 2px solid;}
.is-dark-text .is-title4-48 {border:#000 2px solid;}
.is-dark-text .is-title4-32 {border:#000 2px solid;}

/* Style 5 */

.is-title5-96 {margin-top:10px;margin-bottom:35px;padding-bottom:25px;border-bottom:#000 2px solid;}
.is-title5-80 {margin-top:10px;margin-bottom:33px;padding-bottom:25px;border-bottom:#000 2px solid;}
.is-title5-64 {margin-top:10px;margin-bottom:30px;padding-bottom:20px;border-bottom:#000 2px solid;}
.is-title5-48 {margin-top:10px;margin-bottom:25px;padding-bottom:20px;border-bottom:#000 2px solid;}
.is-title5-32 {margin-top:10px;margin-bottom:20px;padding-bottom:20px;border-bottom:#000 2px solid;}

.is-light-text .is-title5-96 {border-bottom:#fff 2px solid;}
.is-light-text .is-title5-80 {border-bottom:#fff 2px solid;}
.is-light-text .is-title5-64 {border-bottom:#fff 2px solid;}
.is-light-text .is-title5-48 {border-bottom:#fff 2px solid;}
.is-light-text .is-title5-32 {border-bottom:#fff 2px solid;}

.is-dark-text .is-title5-96 {border-bottom:#000 2px solid;}
.is-dark-text .is-title5-80 {border-bottom:#000 2px solid;}
.is-dark-text .is-title5-64 {border-bottom:#000 2px solid;}
.is-dark-text .is-title5-48 {border-bottom:#000 2px solid;}
.is-dark-text .is-title5-32 {border-bottom:#000 2px solid;}

/* Extra Title Styles */

.is-title-lite {letter-spacing:3px;word-spacing:5px;}
.is-title-lite.is-title3-96, .is-title-lite.is-title4-96, .is-title-lite.is-title5-96 {letter-spacing:4px;word-spacing:8px;}
.is-title-lite.is-title3-80, .is-title-lite.is-title4-80, .is-title-lite.is-title5-80 {letter-spacing:4px;word-spacing:8px;}
.is-title-lite.is-title3-64, .is-title-lite.is-title4-64, .is-title-lite.is-title5-64 {letter-spacing:4px;word-spacing:8px;}
.is-title-lite.is-title3-48, .is-title-lite.is-title4-48, .is-title-lite.is-title5-48 {letter-spacing:4px;word-spacing:8px;}
.is-title-lite.is-title3-32, .is-title-lite.is-title4-32, .is-title-lite.is-title5-32 {letter-spacing:3px;word-spacing:5px;}

.is-title-bold {font-weight:800;}
.is-title-bold.is-title3-96, .is-title-bold.is-title4-96, .is-title-bold.is-title5-96 {border-width:4px;}
.is-title-bold.is-title3-80, .is-title-bold.is-title4-80, .is-title-bold.is-title5-80 {border-width:4px;}
.is-title-bold.is-title3-64, .is-title-bold.is-title4-64, .is-title-bold.is-title5-64 {border-width:3px;}
.is-title-bold.is-title3-48, .is-title-bold.is-title4-48, .is-title-bold.is-title5-48 {border-width:3px;}
.is-title-bold.is-title3-32, .is-title-bold.is-title4-32, .is-title-bold.is-title5-32 {border-width:2px;}


/**********************************
    Into Styles
***********************************/

.is-info1 {margin-top:10px;margin-bottom:0px;font-style:italic;}
.is-info1.size-21 {margin-top:12px;}
.is-info1.size-24 {margin-top:15px;}

.is-info2 {margin-top:10px;margin-bottom:0px;}
.is-info2.size-21 {margin-top:12px;}
.is-info2.size-24 {margin-top:15px;}


/**********************************
    Buttons Styles
***********************************/

.is-btn {
    padding: 10px 50px;
    font-size: 1em;
    line-height: 2em;
    border-radius: 0;
    letter-spacing: 3px;

    display: inline-block;
    margin-bottom: 0;
    font-weight: normal;
    text-align: center;
    text-decoration: none;
    vertical-align: middle;
    cursor: pointer;
    background-image: none;
    border: 1px solid transparent;
    white-space: nowrap;
    -webkit-transition: all 0.16s ease;
    transition: all 0.16s ease;
}

/* ghost1 default */
.is-btn-ghost1 { color: #000; border: 2px solid #111; }
.is-btn-ghost1:hover, .is-btn-ghost1:active, .is-btn-ghost1:focus {
    color: #fff;
    background-color: #111;
    border: 2px solid #111;
}

/* ghost1 light-text */
.is-light-text .is-btn-ghost1,
.is-dark-text .is-light-text .is-btn-ghost1  { color: #fff; border: 2px solid #fff;}
.is-light-text .is-btn-ghost1:hover, .is-light-text .is-btn-ghost1:active, .is-light-text .is-btn-ghost1:focus,
.is-dark-text .is-light-text .is-btn-ghost1:hover, .is-dark-text .is-light-text .is-btn-ghost1:active, .is-dark-text .is-light-text .is-btn-ghost1:focus  {
    color: #000;
    background-color: #fff;
    border: 2px solid #fff;
}

/* ghost1 dark-text */
.is-dark-text .is-btn-ghost1,
.is-light-text .is-dark-text .is-btn-ghost1 { color: #000; border: 2px solid #111; }
.is-dark-text .is-btn-ghost1:hover, .is-dark-text .is-btn-ghost1:active, .is-dark-text .is-btn-ghost1:focus,
.is-light-text .is-dark-text .is-btn-ghost1:hover, .is-light-text .is-dark-text .is-btn-ghost1:active, .is-light-text .is-dark-text .is-btn-ghost1:focus  {
    color: #fff;
    background-color: #111;
    border: 2px solid #111;
}

/* ghost2 default */
.is-btn-ghost2 { color: #000; border: 2px solid #d7d7d7; background-color: #d7d7d7; }
.is-btn-ghost2:hover, .is-btn-ghost2:active, .is-btn-ghost2:focus {
    color: #000;
    background-color: #cdcdcd;
    border: 2px solid #cdcdcd;
}

/* ghost2 light-text */
.is-light-text .is-btn-ghost2,
.is-dark-text .is-light-text .is-btn-ghost2  { color: #000; border: 2px solid #f9f9f9; background-color: #f9f9f9; }
.is-light-text .is-btn-ghost2:hover, .is-light-text .is-btn-ghost2:active, .is-light-text .is-btn-ghost2:focus,
.is-dark-text .is-light-text .is-btn-ghost2:hover, .is-dark-text .is-light-text .is-btn-ghost2:active, .is-dark-text .is-light-text .is-btn-ghost2:focus  {
    color: #000;
    background-color: #fff;
    border: 2px solid #fff;
}

/* ghost2 dark-text */
.is-dark-text .is-btn-ghost2,
.is-light-text .is-dark-text .is-btn-ghost2  { color: #000; border: 2px solid #d7d7d7; background-color: #d7d7d7; }
.is-dark-text .is-btn-ghost2:hover, .is-dark-text .is-btn-ghost2:active, .is-dark-text .is-btn-ghost2:focus,
.is-light-text .is-dark-text .is-btn-ghost2:hover, .is-light-text .is-dark-text .is-btn-ghost2:active, .is-light-text .is-dark-text .is-btn-ghost2:focus {
    color: #000;
    background-color: #cdcdcd;
    border: 2px solid #cdcdcd;
}

.is-btn-small { padding: 5px 25px; font-size: 0.85em; }
.is-upper { text-transform:uppercase; }
.is-rounded-30 { border-radius: 30px; }



/* Old Buttons */

.btn {
    padding: 7px 25px;
    font-size: 1em;
    line-height: 2em;
    border-radius: 5px;
    letter-spacing: 1px;

    display: inline-block;
    margin-bottom: 0;
    font-weight: normal;
    text-align: center;
    text-decoration: none;
    vertical-align: middle;
    cursor: pointer;
    background-image: none;
    border: 1px solid transparent;
    white-space: nowrap;
    -webkit-transition: all 0.16s ease;
    transition: all 0.16s ease;
}

/**********************************
    Header Image with Caption
***********************************/
figure.hdr {
	position: relative;
	width: 100%;
	overflow:hidden;
    background-color: #000;
}
figure.hdr img {
	position: relative;
	display: block;
	width: 100%;
	opacity: 0.8;
	-webkit-transition: opacity 0.35s, -webkit-transform 0.35s;
	transition: opacity 0.35s, transform 0.35s;
	-webkit-transform: scale(1.2);
	transform: scale(1.2);
}
figure.hdr:hover img {
	opacity: 0.5;
	-webkit-transform: scale(1);
	transform: scale(1);
}
figure.hdr figcaption {
   	position: absolute;
	top: auto;
	bottom: 0;
	left: 0;
	width: 100%;
	height: 60%;
	padding: 0 2.5em;
	color: #fff;
	font-size: 1.55em;
	text-align: center;
	box-sizing: border-box;
	z-index:1;
}
/* Text */
figure.hdr h2 {
	font-weight: 300;
	text-transform: uppercase;
}
figure.hdr h2 span {
	font-weight: 800;
}
figure.hdr p {
	letter-spacing: 1px;
	font-size: 68.5%;
	text-transform: uppercase;
}
figure.hdr h2, figure.hdr p {
	margin: 0;
	z-index:10000;
}
/* Cosmetic */
figure.hdr div {
	height: 100%;
	z-index:0;
}
figure.hdr div::before,
figure.hdr div::after {
	position: absolute;
	content: '';
}
/* One */
figure.one div::before {
	top: 50px;
	right: 30px;
	bottom: 50px;
	left: 30px;
	border-top: 1px solid #fff;
	border-bottom: 1px solid #fff;
}
figure.one div::after {
	top: 30px;
	right: 50px;
	bottom: 30px;
	left: 50px;
	border-right: 1px solid #fff;
	border-left: 1px solid #fff;
}
/* Two */
figure.two div::before {
	top: 30px;
	right: 30px;
	bottom: 30px;
	left: 30px;
	border-top: 1px solid #fff;
	border-bottom: 1px solid #fff;
}
figure.two div::after {
	top: 30px;
	right: 30px;
	bottom: 30px;
	left: 30px;
	border-right: 1px solid #fff;
	border-left: 1px solid #fff;
}
/* Three */
figure.three figcaption {
	height: 70%;
}
figure.three p {
	margin: 1em 0 0;
	padding: 2em;
	border: 1px solid #fff;
}
/* Four */
figure.four figcaption {
	height: 60%;
	text-align: left;
}
figure.four p {
	position: absolute;
	right: 50px;
	bottom: 50px;
	left: 50px;
	padding: 2em;
	border: 7px solid #fff;
}
/* Five */
figure.five figcaption {
	height: 100%;
	text-align: right;
}
figure.five h2 {
    position: absolute;
    left: 50px;
	right: 50px;
	top: 10%;
	border-bottom: 5px solid #fff;
}
figure.five p {
	position: absolute;
	right: 50px;
	bottom: 10%;
}
/* Six */
figure.six figcaption {
	height: 70%;
}
figure.six h2 {
    padding-bottom: 3%;
	border-bottom: 1px solid #fff;
}
figure.six p {
	padding-top: 6%;
}
/* Seven */
figure.seven figcaption {
	height: 90%;
	text-align:left;
}
figure.seven h2 {
	border-bottom: 3px solid #fff;
}
figure.seven p {
    padding-top: 1em;
}
/* Eight */
figure.eight figcaption {
	height: 100%;
	text-align: right;
}
figure.eight h2 {
	position: absolute;
	left: 50%;
	right: 50px;
	bottom: 10%;
}
figure.eight p {
    position: absolute;
    left: 50px;
	right: 50%;
	top: 10%;
	padding-right:0.5em;
	border-right: 1px solid #fff;
}

.small-scroll::-webkit-scrollbar {
    width: 2px;
    height: 2px;
}
.small-scroll::-webkit-scrollbar-button {
    width: 0px;
    height: 0px;
}
.small-scroll::-webkit-scrollbar-thumb {
    background: #ccc;
    border: 0px none #ffffff;
    border-radius: 57px;
}
.small-scroll::-webkit-scrollbar-thumb:hover {
    background: #ffffff;
}
.small-scroll::-webkit-scrollbar-thumb:active {
    background: #858585;
}
.small-scroll::-webkit-scrollbar-track {
    background: #fff0ef;
    border: 0px none #ffffff;
    border-radius: 50px;
}
.small-scroll::-webkit-scrollbar-track:hover {
    background: #ccc;
}
.small-scroll::-webkit-scrollbar-track:active {
    background: #333333;
}
.small-scroll::-webkit-scrollbar-corner {
    background: transparent;
}
